.container {
    max-width: 100%;
    height: 320px;
    border-radius: 16px;
    background-color: var(--container-bg-color);
    transition: background-color 0.5s ease-in;
    padding: 12px;
    overflow: hidden;
}

.container.selected {
    box-shadow: inset 0 0 0 2px rgba(74, 61, 253, 0.5);
}

.container.zoomed {
    height: 100%;
}

.container.stackedZoom {
    height: 100vh;
}

.svgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: auto;
}

.small {
    height: 35%;
}

.medium {
    height: 55%;
}

.large {
    height: 90%;
}

.noHeight {
    height: unset;
}

.header {
    margin-left: 6px;
}

.noData {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
