.contentContainer {
    display: flex;
    flex-direction: column;
    margin: 40px 30px 24px 32px;
    font-family: "Golos Regular";
}

.contentHeader {
    color: var(--text-color);
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-family: "Golos Bold";
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.additionalMr {
    margin-top: 1em;
}

.ulTitle {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.list {
    color: var(--text-color);
    height: calc(100% - 200px);
}

.list ul {
    list-style-type: none;
    padding-left: 0;
}

.list ul li {
    font-size: 14px;
    line-height: 1.5;
}

.list ul {
    padding-left: 0;
}

.list ol li {
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
}

.closeXIcon img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
}

.listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.itemText {
    font-size: 14px;
    flex-grow: 1;
    word-wrap: break-word;
    max-width: calc(100% - 30px - 50px);
    margin-right: 40px;
}

.removeItem {
    color: #4A3DFD;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
}

.removeAllButtonContainer {
    cursor: pointer;
    width: 135px;
    height: 35px;
    display: flex;
    border-radius: 8px;
    background: #E1E7EF;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.nestedItem {
    margin-left: 20px;
}

.btnContainter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hidden {
    visibility: hidden;
}




