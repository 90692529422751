.leftPart {
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-left: 1em;
    min-height: 3em;
    align-items: center;
    position: relative;
}

.item {
    color: #8D96B2;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Golos Regular';
}

.slider {
    content: '';
    position: absolute;
    bottom: 2px;
    height: 2px;
    background-color: #4A3DFD;
    transition: all 0.3s;
}

.activeItem {
    color: #4A3DFD;
}

.tabDataContainer {
    margin-top: 20px;
}
