.container {
    max-width: 100%;
    min-height: 355px;
    max-height: 355px;
    border-radius: 16px;
    background-color: var(--container-bg-color);
    transition: background-color 0.5s ease-in;
    padding: 20px;
    overflow: hidden;
}

.containerList {
    max-width: 100%;
    border-radius: 16px;
    background-color: var(--container-bg-color);
    transition: background-color 0.5s ease-in;
    padding: 20px;
    overflow: hidden;
}

.container.selected {
    box-shadow: inset 0 0 0 2px rgba(74, 61, 253, 0.5);
}

.tariffHeader {
    color: var(--text-color);
    transition: color 0.5s ease-in;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
    font-size: 18px;
}

.tariffName {
    color: var(--text-color);
    transition: color 0.5s ease-in;
    font-size: 24px;
    margin-bottom: 22px;
}

.expirationDate {
    font-size: 14px;
    color: #8D96B2;
    margin-bottom: 40px;
}

.tariffDetails {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    justify-content: start;
}

.detailItem {
    width: 150px;
    background: #f5f5f7;
    border-radius: 16px;
    padding-top: 16px;
    padding-left: 15px;
    padding-bottom: 17px;
    text-align: start;
}

.detailName {
    font-size: 14px;
    display: block;
    color: #343B4C;
    margin-bottom: 4px;
}

.detailValue {
    font-size: 20px;
    display: block;
}

.changeTariffButton {
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    background-color: var(--button-bg-color);
    transition: background-color 0.5s ease-in;
    color: #4A3DFD;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 10px;
}

.fieldName {
    color: #8D96B2;
    font-size: 14px;
    display: block;
    max-width: 60%;
}

.fieldValue {
    font-family: var(--font-family);
    font-size: 16px;
        border: none;
        background-color: transparent;
        resize: none;
        overflow: hidden;
        padding: 0;
        margin: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.nonEditable {
    cursor: text;
    color: var(--text-color);
    transition: color 0.5s ease-in;
    outline: none;
}

.nonEditable:focus {
    outline: none;
}

.infoBlock {
    display: flex;
    flex-direction: row;
}

.profileIcon {
    width: 80px;
    height: 80px;
}

.initialsAvatar {
    width: 80px;
    height: 80px;
    display: flex;
    font-size: 1.5em;
    justify-content: center;
    align-items: center;
    border: 1px solid #4A3DFD;
    border-radius: 50%;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.uploadButton {
    margin-top: 8px;
    color: #4A3DFD;
    cursor: pointer;
    font-size: 12px;
}


html::-webkit-scrollbar, textarea::-webkit-scrollbar {
    width: 5px;
}

html::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
    background: #A2C4FF;
    border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover, textarea::-webkit-scrollbar-thumb:hover {
    background: #D0E1FF;
}
