
.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

.modalHeading {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 1em;
}

.modalDesc {
    font-size: 18px;
    margin-bottom: 2em;
}

.sendButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 8px;
    width: 80%;
    background-color: #5641FF;
    border: none;
    color: white;
    cursor: pointer;
    margin: 0 auto;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 400px;
    animation: modalFadeIn 0.3s ease-in-out;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}
