.cardContainer {
    padding: 2em;
}

.headerSearch {
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.headerTitle {
    font-size: 26px;
    font-family: "Golos Medium";
}

.subHeader {
    color: #8D96B2;
}

.sortIco {
    cursor: pointer;
}
