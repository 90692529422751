
.background {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../../common/background.webp');
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    pointer-events: none;
}

.loading {
    width: 100%;
    height: 100%;
    color: #FFFFFF;
}

.authButton {
    background: #315EFB;
    font-size: 14px;
    text-transform: none;
    padding: 8px 16px;
}
