.headerD {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.icons {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.iconContainer {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activeIconContainer {
    background: #F7FAFC;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25%;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    font-family: 'Golos Regular';
    font-size: 15px;
    color: var(--text-color);
    transition: color 0.6s ease-in;
    font-weight: 400;
    margin-bottom: 5px;
}

.menuHidden {
    position: absolute;
    width: 200px;
    height: 96px;
    background: #FFF;
    border-radius: 12px;
    box-shadow: 2px 6px 40px 0px rgba(0, 0, 0, 0.15);
    z-index: 100;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    visibility: hidden;
}

.menuVisible {
    position: absolute;
    width: 200px;
    height: 96px;
    background: #FFF;
    border-radius: 12px;
    box-shadow: 2px 6px 40px 0px rgba(0, 0, 0, 0.15);
    z-index: 100;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.4s ease, transform 0.3s ease;
    visibility: visible;
}

.menuItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Golos Regular';
    font-size: 14px;
    color: #8D96B2;
    width: 100%;
    padding: 16px;
    gap: 10px;

}

.menuItems > div {
    padding: 5px 0;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    width: 30em;
    height: auto;
    z-index: 9999;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

