.circleContainer {
    margin-top: 2em;
    background: white;
    padding-left: 2em;
    padding-right: 2em;
    width: 920px;
    min-height: 56px;
    border-radius: 12px;
    display: flex;
    gap: 2.5em;
    justify-content: space-around;
    z-index: 999;
    box-shadow: 0px 8px 24px 0px rgba(16, 30, 115, 0.06);
}

.circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #000;
    transition: background-color 0.3s;
}

.circle:hover {
    cursor: pointer;
}

.circle.filled {
    background-color: #4A3DFD;
}

.circleLabelContainer {
    gap: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    font-size: 16px;
}

.gradientPeach {
    z-index: 1;
    max-width: 1150px;
    max-height: 1150px;
    position: absolute;
    pointer-events: none;
}

.rightCircle {
    right: -30%;
    top: -30%;
}

.rightCircleBottom {
    right: -80%;
    top: -30%;
}

.gradientCircleBlue {
    z-index: 1;
    max-width: 1000px;
    max-height: 1000px;
    position: absolute;
    pointer-events: none;
}

.headerCircle {
    right: 45%;
    top: -30%;
}

.LeftCircleBottom {
    right: 30%;
    top: -30%;
}
