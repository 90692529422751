.blockPart {
    color: #8D96B2;
    gap: 0.5em;
}

.participantsTitle {
   margin-bottom: 0.5em;
}

.participantsList {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.participant {
    padding-left: 0;
    margin-bottom: 0.5em;
    cursor: pointer;
    transition: color 0.3s;
}

.participant:hover {
    color: #4A3DFD;
}

.more:hover {
    text-decoration: underline;
}

.more {
    cursor: pointer;
    color: #4A3DFD;
    margin-top: 0.5em;
}
