.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    width: 420px;
    border-radius: 24px;
    padding: 1em;
    height: 100%;
    box-shadow: 0px 8px 24px 0px rgba(16, 30, 115, 0.06);
    z-index: 3;
    position: relative;
}

.formTitle {
    font-size: 24px;
    font-family: "Golos Medium";
    margin-bottom: 0.2em;
}

.formSubTitle {
    font-size: 16px;
}

.label {
    display: block;
    margin-bottom: 1em;
}

.inputField, .textAreaField {
    width: 94%;
    height: 2em;
    padding: 8px;
    margin-bottom: 0.5em;
    padding-left: 16px;
    border: 1px solid #F7FAFC;
    border-radius: 6px;
    background: #F7FAFC;
    transition: border-color 0.3s;
}

.inputField:focus,
.textAreaField:focus {
    border-color: #4A3DFD;
    background: #FFFFFF;
    outline: none;
}

.inputField::placeholder,
.textAreaField::placeholder {
    transition: transform 0.3s, opacity 0.3s;
    transform-origin: bottom left;
    opacity: 1;
    font-size: 15px;
    color: #8D96B2;
}

.inputField:focus::placeholder,
.textAreaField:focus::placeholder {
    transform: translateY(-20px);
    opacity: 0;
}

.textAreaField {
    height: 100px;
    resize: none;
    margin-bottom: 0;
}

.submitButton {
    background-color: #4A3DFD;
    color: white;
    border: none;
    padding: 1em;
    border-radius: 0.5em;
    cursor: pointer;
}

.inputsContainer {
    margin-top: 0.5em;
}

.flexRow {
    display: flex;
    justify-content: space-between;
}

.halfWidth {
    margin-top: 0.5em;
    width: calc(50% - 4px);
    margin-right: 8px;
}

.flexRow > :last-child {
    margin-right: 0;
}

.afterMail {
    line-height: 16px;
    font-size: 12px;
    color: #BBC0D2;
}

.alignCenter {
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.checkBoxContainer {
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;
    gap: 14px;
    padding: 6px;
}

.bottomContainer {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
}
