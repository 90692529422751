.cardContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 417px;
    min-width: 348px;
}

.cardHeader {
    font-family: "Golos Medium";
    font-size: 24px;
    color: #333333;
    text-align: start;
}

.cardSubTitle {
    font-size: 16px;
    margin-top: 2em;
    text-align: start;
    margin-bottom: 1em;
}

.ulList {
    padding-top: 0.5em;
}

.listItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: start;
    margin-top: -0.3em;
    font-size: 18px;
    color: #333333;
}

.listItemContant {
    color: #1F283E;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 5px;
    width: 100%;
    line-height: 24px;
    height: auto;
    max-width: 280px;
}

.cardList {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;
}

.cardList > li:first-child {
    margin-top: 1px;
}

.cardListNoGap {
    gap: 0;
}

.itemPrice {
    font-family: "Golos Medium";
    font-size: 40px;
    text-align: start;
    margin-bottom: 0.1em;
}

.itemPriceLow {
    color: #8D96B2;
    text-align: start;
    margin-bottom: 1em;
}

.cardFooter {
    width: 100%;
    padding: 12px;
    background-color: #4A3DFD;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 1em;
}

.cardIcon {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.rightAlignedText::-webkit-inner-spin-button,
.rightAlignedText::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.rightAlignedText {
    -moz-appearance: textfield;
    text-align: right;
}

.textField {
    width: 64px;
    border-radius: 8px;
}

.textField input {
    text-align: right;
}

.sliderContent {
    gap: 0.3em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sliderSlider {
    flex-grow: 1;
}

.labelText {
    font-size: 16px;
}

.itemPriceWithExtraMargin {
    margin-bottom: 2em;
}

.itemPriceWithExtraMarginConfigure {
    margin-bottom: 0.9em;
}

.itemPriceContainer {
    display: flex;
    align-items: flex-start;
}

.itemPriceFadeIn {
    transition: opacity 0.5s;
    opacity: 0;
}

.itemPriceFadeInActive {
    opacity: 1;
}

.discountTag {
    background-color: #EADCFF;
    color: #4F19AB;
    border-radius: 12px;
    padding: 0.2em 0.6em;
    font-size: 0.9em;
    margin-left: 0.5em;
    white-space: nowrap;
}

@media (max-width: 1024px) {
    .cardContainer {
        width: 600px;
    }
}

@media (max-width: 767px) {

    .cardContainer {
        width: 100%;
        max-width: 417px;
        min-width: 348px;
        height: 620px;
    }

    .labelText {
        font-size: 14px;
    }
}

@media (max-width: 425px) {
    .cardContainer {
        height: 590px;
    }
    .labelText {
        font-size: 14px;
    }
    .labelCount {
        font-size: 14px;
    }
    .cardHeader {
        font-size: 22px;
    }
    .cardSubTitle {
        font-size: 14px;
    }
    .itemPrice {
        font-size: 34px;
    }
    .itemPriceLow {
        font-size: 12px;
    }
    .cardFooter {
        font-size: 16px;
    }
}


