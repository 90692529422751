.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--container-bg-color);
    height: 56px;
    border-bottom: 2px solid var(--border-color);
    transition: background-color 0.5s ease-in, border-bottom 0.5s ease-in;
    user-select: none;
    position: relative;
}

.leftPart {
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-left: 40px;
    align-items: center;
    position: relative;
}

.item {
    color: #8D96B2;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 0.5em;
}

.slider {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #4A3DFD;
    transition: all 0.3s;
}

.activeItem {
    color: #4A3DFD;
}

.plusIcon {
    margin-top: 3px;
}

.iconContainer {
    margin-right: 40px;
    display: flex;
    gap: 16px;
}

.iconButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.iconButton:focus {
    outline: none;
}

.valueContainer {
    color: #8D96B2;
    border: 1px solid #8D96B2;
    padding: 5px;
    border-radius: 5px;
    user-select: text;
}

.closeIcon {
    position: absolute;
    top: 15%;
    right: -0.8px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: #FFFFFF;
}

.filterComponent {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 300px;
    height: 2em;
    background-color: var(--sliderBtn-bg-color);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    padding: 0.4em;
    transform: translateX(100%);
    right: 10.5em;
    top: 0.30em;
    pointer-events: none;
    z-index: 99999;
}

.filterComponent.visible {
    opacity: 1;
    transform: translateX(0);
    pointer-events: all;
}

.checkboxLabel {
    color: var(--text-color);
}

.checkboxContainer {
    margin-left: 0.5em;
    gap: 0.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.searchIconsContainer {
    margin-left: 1.5em;
    padding-right: 2em;
    display: flex;
    align-items: center;
    gap: 10px;
}

.customMrIco {
    margin-left: 0.2em;
}

.searchIcon {
    cursor: pointer;
}
